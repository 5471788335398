import { decode } from 'he';
import React, { useRef, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Swiper from 'swiper';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  ArrowSvg,
  Wrapper,
  CropableGatsbyImage,
  ImgWrapper,
  ContentOuter,
  ContentInner,
  Tag,
  SlideTitle,
  Footer,
  Title,
  Subtitle,
} from './styled';
import { colors } from '../../ui/common';

function Arrow() {
  return (
    <ArrowSvg viewBox="0 0 53 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 2.6a.4.4 0 000 .8v-.8zm51.283.683a.4.4 0 000-.566L49.737.172a.4.4 0 00-.565.565L51.434 3l-2.262 2.263a.4.4 0 00.565.565l2.546-2.545zM1 3.4h51v-.8H1v.8z"
        fill={colors.color05}
      />
    </ArrowSvg>
  );
}

function Blog() {
  const locale = useSelector((state) => state.locale.picked);

  if (locale === 'ru') return null;

  return <RawBlog />;
}

function RawBlog() {
  const { evaBlog } = useStaticQuery(graphql`
    {
      evaBlog {
        dataList {
          url
          title
          tag
          imageSrc
        }
        imageList {
          childImageSharp {
            gatsbyImageData(width: 438, quality: 85, layout: CONSTRAINED)
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const sliderRef = useRef();
  const paginationRef = useRef();
  useEffect(() => {
    const swiper = new Swiper(sliderRef.current, {
      slidesPerView: 'auto',
      centeredSlides: true,
      spaceBetween: 18,
      grabCursor: true,
      breakpoints: {
        550: {
          spaceBetween: 40,
          centeredSlides: false,
        },
      },
      pagination: {
        el: paginationRef.current,
        clickable: true,
      },
    });

    return () => {
      swiper.destroy();
    };
  }, []);

  if (evaBlog.dataList.length === 0) return null;

  return (
    <Wrapper>
      <Title>{intl.formatMessage({ id: 'blog.title' })}</Title>
      <Subtitle>{intl.formatMessage({ id: 'blog.subtitle' })}</Subtitle>
      <div className="swiper-container" ref={sliderRef}>
        <div className="swiper-wrapper">
          {evaBlog.dataList.map((item, i) => (
            <a
              className="swiper-slide"
              key={item.url}
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ImgWrapper>
                <CropableGatsbyImage
                  image={evaBlog.imageList[i].childImageSharp.gatsbyImageData}
                  alt=""
                />
              </ImgWrapper>
              <ContentOuter>
                <ContentInner>
                  <Tag>{decode(item.tag)}</Tag>
                  <SlideTitle>{item.title}</SlideTitle>
                  <Footer>
                    {intl.formatMessage({ id: 'blog.keep.reading' })}
                    <Arrow />
                  </Footer>
                </ContentInner>
              </ContentOuter>
            </a>
          ))}
        </div>
        <div className="swiper-pagination" ref={paginationRef} />
      </div>
    </Wrapper>
  );
}

export default Blog;
