import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { colors } from '../../ui/common';

const SLIDER_THRESHOLD = '550px';

export const ArrowSvg = styled.svg`
  flex: 0 0 54px;
`;

export const Footer = styled.span`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.color01};
  font-size: 10px;
  transform: translateX(100%);
  transition: transform 0.25s ease-out;
`;

export const Wrapper = styled.div`
  margin: 80px 0 90px;

  @media (min-width: 768px) {
    margin: 74px 0 80px;
  }
  @media (min-width: 960px) {
    margin: 90px 0 80px;
  }

  & .swiper-container {
    @media (min-width: ${SLIDER_THRESHOLD}) {
      padding: 0 24px;
    }
  }

  & .swiper-wrapper {
    margin-bottom: 72px;
  }

  & .swiper-slide {
    transition: box-shadow 0.25s ease-out;
    height: auto;
    border-radius: 6px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: 300px;

    &:hover {
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);

      & ${Footer} {
        transform: translateX(0);
      }
    }

    @media (min-width: ${SLIDER_THRESHOLD}) {
      width: 438px;
    }
  }

  & .swiper-pagination-bullet {
    width: 18px;
    height: 18px;
    background: rgba(210, 240, 240, 0.8);
    opacity: 1;

    &.swiper-pagination-bullet-active {
      background: ${colors.color05};
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    }
  }
`;

export const CropableGatsbyImage = styled(GatsbyImage)`
  height: 100%;
`;

export const ImgWrapper = styled.div`
  overflow: hidden;
  height: 232px;
  border-radius: 6px 6px 0 0;
`;

export const ContentOuter = styled.span`
  flex: 1;
  padding: 20px 38px;
  display: flex;
  flex-direction: column;
`;

export const ContentInner = styled.span`
  overflow: hidden;
`;

export const Tag = styled.span`
  display: block;
  margin: 0;
  color: #fa7814;
  opacity: 0.7;
  font-size: 10px;
  text-transform: uppercase;
`;

export const SlideTitle = styled.span`
  display: block;
  margin: 16px 0;
  color: ${colors.color05};
  font-size: 16px;
`;

export const Text = styled.p`
  text-align: center;
  margin: 0 auto 16px;
  color: ${colors.color05};
`;

export const Title = styled(Text)`
  font-weight: 600;
  font-size: 32px;
  line-height: 150%;

  @media (min-width: 768px) {
    margin-bottom: 20px;
    font-size: 38px;
  }
`;

export const Subtitle = styled(Text)`
  font-weight: 300;
  font-size: 16px;
  line-height: 192%;
  opacity: 0.9;
  margin-bottom: 70px;
  max-width: 360px;

  @media (min-width: 768px) {
    line-height: 170%;
    margin-bottom: 64px;
  }
  @media (min-width: 960px) {
    line-height: 180%;
    margin-bottom: 86px;
    max-width: none;
  }
`;
